import { AuthorsField } from "./src/fields/authors"
import { DateFieldPlugin } from "react-tinacms-date"

export const onClientEntry = () => {
  window.tinacms.fields.add({
    name: "authors",
    Component: AuthorsField,
  })
  window.tinacms.plugins.add(DateFieldPlugin)
}
